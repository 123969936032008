import React, { useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import PropTypes from 'prop-types'
import { RichText } from '@/components/RichText'
import ExpandSVG from './images/expand.inline.svg'
import CollapseSVG from './images/collapse.inline.svg'
import * as styles from './TextAccordionItem.module.scss'

const TextAccordionItem = ({ heading, content, defaultOpen }) => {
  const [isOpen, setIsOpen] = useState(defaultOpen)
  const handleToggle = () => setIsOpen(!isOpen)

  return (
    <div className={styles.TextAccordionItem} data-open={isOpen}>
      <motion.button
        type="button"
        className={styles.head}
        onClick={handleToggle}
        initial={false}
      >
        <h4 className={styles.heading}>{heading}</h4>
        <div className={styles.icon}>
          <motion.div
            className={styles.icon__expand}
            animate={isOpen ? 'hidden' : 'visible'}
            variants={{
              visible: { opacity: 1, scale: 1 },
              hidden: { opacity: 0, scale: 0 },
            }}
            transition={{ duration: 0.2 }}
          >
            <ExpandSVG />
          </motion.div>
          <motion.div
            className={styles.icon__collapse}
            animate={isOpen ? 'visible' : 'hidden'}
            variants={{
              visible: { opacity: 1, scale: 1 },
              hidden: { opacity: 0, scale: 0 },
            }}
            transition={{ duration: 0.2 }}
          >
            <CollapseSVG />
          </motion.div>
        </div>
      </motion.button>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.5, ease: 'circOut' }}
            className={styles.content}
          >
            <div className={styles.content__inner}>
              <RichText>{content}</RichText>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

TextAccordionItem.propTypes = {
  heading: PropTypes.string,
  content: PropTypes.node,
  defaultOpen: PropTypes.bool,
}

export { TextAccordionItem }
