import React from 'react'
import PropTypes from 'prop-types'
import { Container } from '@/components/Container'
import { Block } from '@/components/Block'
import { Img } from '@/components/Img'
import { ImageCredit } from '@/components/ImageCredit'
import * as styles from './MultiImageLayout.module.scss'

const MultiImageLayout = ({ images, background, filter, align }) => {
  let verticalAlign = 'default'

  if (images.length === 2) {
    if (images[0].align === 'top' && images[1].align === 'bottom') {
      images[1].offset = `${
        (images[0].image.height / images[0].image.width) * 50
      }%`
    } else if (images[0].align === 'bottom' && images[1].align === 'top') {
      images[0].offset = `${
        (images[1].image.height / images[1].image.width) * 50
      }%`
    } else if (images[0].align === 'bottom' && images[1].align === 'bottom') {
      verticalAlign = 'bottom'
    } else if (images[0].align === 'middle' && images[1].align === 'middle') {
      verticalAlign = 'middle'
    }
  }

  return (
    <Block className={styles.MultiImageLayout} background={background}>
      <div className={styles.inner}>
        <Container>
          <div
            className={styles.container}
            data-vertical-align={verticalAlign}
            data-horizontal-align={align}
            data-images={images.length}
          >
            {images.map((item) => {
              const image = {
                filter,
                ...item.image,
              }

              return (
                <div
                  className={styles.column}
                  data-align={item.align}
                  key={item.id}
                >
                  {(image.srcset || image.src) && (
                    <>
                      {item.offset && (
                        <div
                          className={styles.column__spacer}
                          style={{ paddingTop: item.offset }}
                        />
                      )}
                      <button
                        type="button"
                        onClick={item.image.onClick}
                        className={styles.imageLink}
                      >
                        <Img
                          {...image}
                          showPlaceholder
                          placeholderColor={
                            background === 'light-brown' ? '#CCC1B5' : null
                          }
                        />
                      </button>
                      {item.credit && (
                        <ImageCredit>Image: {item.credit}</ImageCredit>
                      )}
                    </>
                  )}
                </div>
              )
            })}
          </div>
        </Container>
      </div>
    </Block>
  )
}

MultiImageLayout.defaultProps = {
  images: [],
  align: 'default',
}

MultiImageLayout.propTypes = {
  images: PropTypes.array,
  background: PropTypes.string,
  filter: PropTypes.string,
  align: PropTypes.string,
}

export { MultiImageLayout }
