// extracted by mini-css-extract-plugin
export var BlogPostsCarousel = "BlogPostsCarousel-module--BlogPostsCarousel--eI6U6";
export var aniSpin = "BlogPostsCarousel-module--ani-spin--4uxBi";
export var buttonNext = "BlogPostsCarousel-module--buttonNext--4+ERM";
export var buttonPrev = "BlogPostsCarousel-module--buttonPrev--3oxSD";
export var carousel = "BlogPostsCarousel-module--carousel--ylHw9";
export var container = "BlogPostsCarousel-module--container--VFfm4";
export var controls = "BlogPostsCarousel-module--controls--Am6WM";
export var controls__inner = "BlogPostsCarousel-module--controls__inner--b1w0J";
export var embla = "BlogPostsCarousel-module--embla--u6haR";
export var embla__container = "BlogPostsCarousel-module--embla__container--EIYh3";
export var embla__item = "BlogPostsCarousel-module--embla__item--KE-0q";
export var group = "BlogPostsCarousel-module--group--pTclO";
export var heading = "BlogPostsCarousel-module--heading--S9iXq";
export var inner = "BlogPostsCarousel-module--inner--e68zj";