/**
 * sortBy: sort an array of objects by key
 * @source https://youmightnotneed.com/lodash/#chunk
 * @param {Array} array
 * @param {Number} chunkSize
 * @param {Array} cache
 */

const chunk = (array, chunkSize = 1, cache = []) => {
  const tmp = [...array]
  if (chunkSize <= 0) return cache
  while (tmp.length) cache.push(tmp.splice(0, chunkSize))
  return cache
}

exports.chunk = chunk
