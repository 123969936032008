import React, { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import { wrap } from '@popmotion/popcorn'
import PropTypes from 'prop-types'
import { useInterval } from '@/hooks/useInterval'
import * as styles from './FadeSlideshow.module.scss'

const preloadImage = (path) =>
  new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => resolve(path)
    img.onerror = () => reject()
    img.src = path
  })

const FadeSlideshow = ({
  images,
  autoplay,
  autoplayDelay,
  controls,
  counter,
  duration,
  preload,
  onReady,
  objectFit,
  objectPosition,
}) => {
  const [slide, setSlide] = useState(0)
  const [isRunning] = useState(autoplay)
  const [ready, setReady] = useState(!preload)
  const imageIndex = wrap(0, images.length, slide)

  const variants = {
    initial: {
      zIndex: 0,
      opacity: 0,
    },
    visible: {
      zIndex: 2,
      opacity: 1,
      transition: {
        opacity: {
          duration: duration / 1000,
          ease: 'linear',
        },
      },
    },
    hidden: {
      zIndex: 1,
      opacity: 0,
      transition: {
        opacity: {
          duration: 0,
          delay: duration / 1000,
          ease: 'linear',
        },
      },
    },
  }

  const paginate = (newDirection) => setSlide(slide + newDirection)

  useEffect(() => {
    Promise.all(images.map((item) => preloadImage(item.src))).then(
      () => {
        setReady(true)
        onReady()
      },
      () => console.error('fade-slideshow: Could not load images') // eslint-disable-line no-console
    )
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useInterval(() => paginate(1), isRunning ? autoplayDelay : null)

  return (
    <div className={styles.FadeSlideshow} data-ready={ready}>
      <div>
        {images.map((image, index) => {
          return (
            <motion.img
              key={image.id}
              src={image.src}
              className={styles.image}
              variants={variants}
              initial="initial"
              animate={imageIndex === index ? 'visible' : 'hidden'}
              style={{
                objectFit,
                objectPosition: image.objectPosition || objectPosition,
                fontFamily: `'object-fit: ${objectFit}; object-position: ${objectPosition}'`,
              }}
            />
          )
        })}
      </div>
      {counter && (
        <div className={styles.counter}>
          {imageIndex + 1} of {images.length}
        </div>
      )}
      {controls && (
        <div>
          <button
            type="button"
            onClick={() => paginate(1)}
            data-direction="next"
            className={styles.navigate}
          >
            ‣
          </button>
          <button
            type="button"
            onClick={() => paginate(-1)}
            data-direction="prev"
            className={styles.navigate}
          >
            ‣
          </button>
        </div>
      )}
    </div>
  )
}

FadeSlideshow.defaultProps = {
  images: [],
  autoplay: false,
  autoplayDelay: 2000,
  controls: true,
  counter: false,
  duration: 1000,
  preload: false,
  onReady: () => {},
  objectFit: 'contain',
  objectPosition: 'center',
}

FadeSlideshow.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      src: PropTypes.string,
      objectFit: PropTypes.string,
    })
  ),
  autoplay: PropTypes.bool,
  autoplayDelay: PropTypes.number,
  controls: PropTypes.bool,
  counter: PropTypes.bool,
  duration: PropTypes.number,
  preload: PropTypes.bool,
  onReady: PropTypes.func,
  objectFit: PropTypes.oneOf(['contain', 'cover']),
  objectPosition: PropTypes.string,
}

export { FadeSlideshow }
