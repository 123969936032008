import React, { useState, useLayoutEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { motion, useViewportScroll, useTransform } from 'framer-motion'
import useMedia from 'use-media'
import LogoSVG from '@/images/logo-oculus.inline.svg'
import { FadeSlideshow } from '@/components/FadeSlideshow'
import * as styles from './Hero.module.scss'

const Hero = ({ hasLogo, images, imagesMobile }) => {
  const isMobile = useMedia({ maxWidth: 768 })
  const [imagesLoaded, setImagesLoaded] = useState(false)
  const [elementTop, setElementTop] = useState(0)
  const ref = useRef(null)
  const { scrollY } = useViewportScroll()
  const y = useTransform(scrollY, [elementTop, elementTop - 50], [0, 5], {
    clamp: false,
  })

  useLayoutEffect(() => {
    const element = ref.current
    setElementTop(element.offsetTop)
  }, [ref])

  const slideshowConfig = {
    controls: false,
    objectFit: 'cover',
    autoplayDelay: 5000,
    duration: 800,
    preload: true,
    autoplay: true,
    counter: true,
    onReady: () => setImagesLoaded(true),
  }

  return (
    <div className={styles.Hero}>
      <div className={styles.spacer} />
      <div className={styles.container} ref={ref}>
        <motion.div
          className={styles.carousel}
          initial={{ opacity: 0 }}
          animate={imagesLoaded && { opacity: 1 }}
        >
          <motion.div className={styles.parallax} style={{ y }}>
            {isMobile && !!imagesMobile?.length ? (
              <FadeSlideshow
                images={imagesMobile}
                {...slideshowConfig}
                counter={images?.length > 1}
              />
            ) : (
              <FadeSlideshow
                images={images}
                {...slideshowConfig}
                counter={images?.length > 1}
              />
            )}
            <div className={styles.darken} />
          </motion.div>
        </motion.div>
        {hasLogo && (
          <div className={styles.logo}>
            <div className={styles.logo__wrapper}>
              <LogoSVG />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

Hero.defaultProps = {
  hasLogo: false,
  images: [],
  imagesMobile: [],
}

Hero.propTypes = {
  hasLogo: PropTypes.bool,
  images: PropTypes.array,
  imagesMobile: PropTypes.array,
}

export { Hero }
