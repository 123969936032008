import React from 'react'
import PropTypes from 'prop-types'

import { BigImage } from '@/blocks/BigImage'
import { BlogPostsCarousel } from '@/blocks/BlogPostsCarousel'
import { BlogPostsGrid } from '@/blocks/BlogPostsGrid'
import { BodyText } from '@/blocks/BodyText'
import { FeaturedProjects } from '@/blocks/FeaturedProjects'
import { Hero } from '@/blocks/Hero'
import { MultiImageLayout } from '@/blocks/MultiImageLayout'
import { ProfilesGrid } from '@/blocks/ProfilesGrid'
import { TextAccordion } from '@/blocks/TextAccordion'

const BLOCK_TYPES = {
  big_image: BigImage,
  blog_posts_carousel: BlogPostsCarousel,
  blog_posts_grid: BlogPostsGrid,
  body_text: BodyText,
  featured_projects: FeaturedProjects,
  hero: Hero,
  multi_image_layout: MultiImageLayout,
  profiles: ProfilesGrid,
  text_accordion: TextAccordion,
}

const BlockRenderer = ({ blocks }) => {
  const output = []
  let prevModule = 'none'
  let nextModule = ''

  blocks.forEach((block, index) => {
    const blockType = block.type
    const BlockComponent = BLOCK_TYPES[blockType]
    if (blocks[index + 1]) {
      nextModule = blocks[index + 1].type || blocks[index + 1].slice_type
    } else {
      nextModule = 'none'
    }
    if (BlockComponent) {
      const propsOutput = block
      output.push(
        <BlockComponent
          key={block.id}
          prev={prevModule}
          next={nextModule}
          scrollEnabled={index !== 0}
          {...propsOutput}
        />
      )

      prevModule = blockType
    }
  })

  return <div>{output}</div>
}

BlockRenderer.propTypes = {
  blocks: PropTypes.arrayOf(PropTypes.object),
}

export { BlockRenderer }
