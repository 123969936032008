import React from 'react'
import PropTypes from 'prop-types'
import { Container } from '@/components/Container'
import { Block } from '@/components/Block'
import { FeaturedProjectThumb } from '@/components/FeaturedProjectThumb'
import * as styles from './FeaturedProjects.module.scss'

const FeaturedProjects = ({ projects }) => {
  return (
    <Block className={styles.FeaturedProjects} scrollEnabled={false}>
      <Container>
        {!!projects?.length && (
          <div className={styles.wrapper}>
            {projects.map((project) => (
              <FeaturedProjectThumb {...project} key={project.id} />
            ))}
          </div>
        )}
      </Container>
    </Block>
  )
}

FeaturedProjects.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.PropTypes.object),
}

export { FeaturedProjects }
