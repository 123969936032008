// extracted by mini-css-extract-plugin
export var FeaturedProjectThumb = "FeaturedProjectThumb-module--FeaturedProjectThumb--UE22n";
export var aniSpin = "FeaturedProjectThumb-module--ani-spin--MLtIP";
export var cta = "FeaturedProjectThumb-module--cta--xZx2h";
export var heading = "FeaturedProjectThumb-module--heading--c6-pn";
export var image = "FeaturedProjectThumb-module--image--GokNJ";
export var image__filter = "FeaturedProjectThumb-module--image__filter--sfWHl";
export var image__hover = "FeaturedProjectThumb-module--image__hover--hajiS";
export var image__inner = "FeaturedProjectThumb-module--image__inner--yR0p4";
export var image__overlay = "FeaturedProjectThumb-module--image__overlay--G7NUr";
export var link = "FeaturedProjectThumb-module--link--oXPwP";
export var link__arrow = "FeaturedProjectThumb-module--link__arrow--u7Wii";
export var link__arrow__bar = "FeaturedProjectThumb-module--link__arrow__bar---PCwj";
export var link__arrow__bottom = "FeaturedProjectThumb-module--link__arrow__bottom--1Frjh";
export var link__arrow__top = "FeaturedProjectThumb-module--link__arrow__top--RICYq";
export var link__text = "FeaturedProjectThumb-module--link__text--iV6oz";
export var link__text__underline = "FeaturedProjectThumb-module--link__text__underline--nxmMQ";
export var link__wrapper = "FeaturedProjectThumb-module--link__wrapper--5mnKL";
export var text = "FeaturedProjectThumb-module--text--XAFGx";