/**
 * sortBy: sort an array of objects by key
 * @source https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore
 * @param {Array} array
 * @param {String} key
 */

const sortBy = (array, key) => {
  return array
    .concat()
    .sort((a, b) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0)) // eslint-disable-line no-nested-ternary
}

exports.sortBy = sortBy
