import React from 'react'
import PropTypes from 'prop-types'
import { Container } from '@/components/Container'
import { RichText } from '@/components/RichText'
import { Block } from '@/components/Block'
import * as styles from './BodyText.module.scss'

const BodyText = ({ textCol01, textCol02, vAlign }) => {
  return (
    <Block className={styles.BodyText}>
      <Container>
        <div className={styles.container} data-valign={vAlign}>
          <div className={styles.column}>
            <RichText>{textCol01}</RichText>
          </div>
          {textCol02 && (
            <div className={styles.column}>
              <RichText>{textCol02}</RichText>
            </div>
          )}
        </div>
      </Container>
    </Block>
  )
}

BodyText.defaultProps = {
  vAlign: 'top',
}

BodyText.propTypes = {
  textCol01: PropTypes.node,
  textCol02: PropTypes.node,
  vAlign: PropTypes.string,
}

export { BodyText }
