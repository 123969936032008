import React, { useCallback } from 'react'
import useMedia from 'use-media'
import useEmblaCarousel from 'embla-carousel-react'
import Autoplay from 'embla-carousel-autoplay'
import { chunk } from '@/utils/chunk'
import { BlogPostThumb } from '@/components/BlogPostThumb'
import { Container } from '@/components/Container'
import { Block } from '@/components/Block'
import * as styles from './BlogPostsCarousel.module.scss'

const BlogPostsCarousel = ({ posts, heading, next, prev }) => {
  const isMobile = useMedia({ maxWidth: 768 })
  const maxPosts = isMobile ? 10 : 9
  const postsPerGroup = isMobile ? 2 : 3
  const postsGrouped = chunk(posts.slice(0, maxPosts), postsPerGroup)

  const [emblaRef, emblaApi] = useEmblaCarousel(
    {
      align: 'center',
      loop: postsGrouped?.length > 2,
    },
    [
      Autoplay({
        delay: 8000,
        stopOnMouseEnter: true,
        stopOnInteraction: false,
      }),
    ]
  )

  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) return
    emblaApi.scrollPrev()
  }, [emblaApi])

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) return
    emblaApi.scrollNext()
  }, [emblaApi])

  return (
    <Block className={styles.BlogPostsCarousel} prev={prev} next={next}>
      <div className={styles.inner}>
        {heading && (
          <Container>
            <h3 className={styles.heading}>{heading}</h3>
          </Container>
        )}
        <div className={styles.carousel}>
          <div className={styles.controls}>
            <div className={styles.controls__inner}>
              <button
                className={styles.buttonNext}
                aria-label="Next"
                onClick={onNextButtonClick}
              />
              <button
                className={styles.buttonPrev}
                aria-label="Previous"
                onClick={onPrevButtonClick}
              />
            </div>
          </div>
          <div className={styles.embla} ref={emblaRef}>
            <div className={styles.embla__container}>
              {postsGrouped?.map((group) => {
                return (
                  <div className={styles.embla__item} key={group[0].id}>
                    <div className={styles.group}>
                      {group.map((thumb) => {
                        return (
                          <div key={thumb.id} data-column>
                            <BlogPostThumb
                              title={thumb.title}
                              url={thumb.route}
                              image={thumb.image}
                              date={thumb.date}
                            />
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </Block>
  )
}

export { BlogPostsCarousel }
