import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import * as styles from './ImageFrameHover.module.scss'

const ImageFrameHover = ({ className, children, color, size }) => {
  return (
    <div
      className={classNames(styles.ImageFrameHover, className)}
      data-color={color}
      data-size={size}
    >
      <div className={styles.top} />
      <div className={styles.right} />
      <div className={styles.bottom} />
      <div className={styles.left} />
      {children}
    </div>
  )
}

ImageFrameHover.defaultProps = {
  color: 'default',
  size: 'default',
}

ImageFrameHover.propTypes = {
  color: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  size: PropTypes.string,
}

export { ImageFrameHover }
