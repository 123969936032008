import { getSlug } from '@/utils/getSlug'
import { getRichText } from '@/prismic/helpers/getRichText'
import { useBlogPosts } from '@/prismic/hooks/useBlogPosts'
import { useProjects } from '@/prismic/hooks/useProjects'
import { useProfiles } from '@/prismic/hooks/useProfiles'
import { getImage } from '@/prismic/helpers/getImage'

/**
 * Takes Prismic slice data and outputs props that
 * can be passed to Blocks
 *
 * @param {array} slices
 */

export const useSlices = (slices) => {
  const allProjects = useProjects()
  const allProfiles = useProfiles()
  const allBlogPosts = useBlogPosts()

  if (!slices?.length) return []

  // const blocks = []

  return slices.map((slice, sliceIndex) => {
    // move 'primary' level to root
    if (slice.primary) {
      Object.keys(slice.primary).forEach((key) => {
        if (Object.prototype.hasOwnProperty.call(slice.primary, key)) {
          slice[key] = slice.primary[key]
        }
      })
      delete slice.primary
    }

    // Create props
    const props = {
      type: slice.slice_type,
      id: slice.id || `slice-${sliceIndex}`,
    }

    switch (slice.slice_type) {
      case 'body_text':
        props.textCol01 = getRichText(slice.column01?.raw)
        props.textCol02 = getRichText(slice.column02?.raw)
        props.vAlign = getSlug(slice.valign)
        break

      case 'blog_posts':
        const isNumber = !Number.isNaN(parseFloat(slice.max_posts))
        const max = isNumber ? slice.max_posts : undefined
        props.heading = slice.heading?.text
        props.posts = allBlogPosts.slice(0, max)
        props.type =
          slice.layout === 'Carousel'
            ? 'blog_posts_carousel'
            : 'blog_posts_grid'

        break

      case 'big_image':
        props.images = []
        slice.items.forEach((item, index) => {
          const image = getImage(item.image)
          if (image?.src) {
            props.images.push({
              id: `${sliceIndex}-${index}`,
              image,
              credit: getRichText(item.credit?.raw),
            })
          }
        })
        break

      case 'featured_projects':
        const featuredProjectUIDs = slice.items.map((item) => item.project?.uid)
        props.projects = featuredProjectUIDs.map((uid) => {
          return allProjects.find((i) => i.uid === uid)
        })
        break

      case 'hero':
        props.hasLogo = slice.style === 'Logo'
        props.images = []
        props.imagesMobile = []
        slice.items.forEach((item, index) => {
          const imageDesktop = getImage(item.desktop_image)
          const imageMobile = getImage(item.mobile_image)
          if (imageDesktop.src) {
            props.images.push({
              id: `${sliceIndex}-${index}`,
              src: imageDesktop.src,
              objectPosition:
                item.image_align && `center ${item.image_align.toLowerCase()}`,
            })
          }
          if (imageMobile.src) {
            props.imagesMobile.push({
              id: `${sliceIndex}-mobile-${index}`,
              src: imageMobile.src,
              objectPosition:
                item.image_align && `center ${item.image_align.toLowerCase()}`,
            })
          }
        })
        break

      case 'multi_image_layout':
        props.background = slice.background
          ? slice.background.replace(/\s+/g, '-').toLowerCase()
          : null
        props.align = slice.align
          ? slice.align.replace(/\s+/g, '-').toLowerCase()
          : null
        props.images = []
        slice.items.forEach((item, index) => {
          const image = getImage(item.image)
          if (image?.src) {
            props.images.push({
              id: `${sliceIndex}-multi-image-${index}`,
              align: item.valign ? item.valign.toLowerCase() : 'top',
              credit: getRichText(item.credit?.raw),
              image,
            })
          }
        })
        break

      case 'profiles':
        props.profiles = allProfiles
        props.heading = slice.heading?.text
        break

      case 'text_accordion':
        props.heading = slice.heading?.text
        props.body = getRichText(slice.body_text?.raw)
        props.items = slice.items?.map((item, index) => ({
          uid: `${sliceIndex}-${index}`,
          heading: item.heading?.text,
          content: getRichText(item.content?.raw),
        }))
        break

      default:
        console.warn(`useSlices: No props found for ${slice.slice_type}`)
    }

    return props
  })
}
