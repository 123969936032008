import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Waypoint } from 'react-waypoint'
import { ImageFrameHover } from '@/components/ImageFrameHover'
import { ArrowIcon } from '@/components/ArrowIcon'
import { Link } from '@/components/Link'
import { Img } from '@/components/Img'
import * as styles from './FeaturedProjectThumb.module.scss'

const FeaturedProjectThumb = ({ title, shortSummary, route, image }) => {
  const [isTransitioned, setIsTransitioned] = useState(false)
  const handleWaypointEnter = () => setIsTransitioned(true)

  return (
    <Waypoint
      onEnter={handleWaypointEnter}
      scrollableAncestor={typeof window !== 'undefined' ? window : undefined}
    >
      <div
        className={styles.FeaturedProjectThumb}
        data-transition-in={isTransitioned}
      >
        <ImageFrameHover className={styles.image}>
          <Link to={route}>
            <span className={styles.image__inner}>
              <span className={styles.image__hover}>
                <span className={styles.image__filter}>
                  {(image?.srcset || image?.src) && <Img {...image} />}
                </span>
              </span>
            </span>
            <span className={styles.image__overlay} />
          </Link>
        </ImageFrameHover>
        <div className={styles.text}>
          <h3 className={styles.heading}>
            {title}
            {shortSummary && `— ${shortSummary}`}
          </h3>
          <div className={styles.cta}>
            <Link to={route} className={styles.link}>
              <span className={styles.link__wrapper}>
                <span className={styles.link__text}>
                  View Project
                  <span className={styles.link__text__underline} />
                </span>
                <ArrowIcon
                  className={styles.link__arrow}
                  barClassName={styles.link__arrow__bar}
                  endTopClassName={styles.link__arrow__top}
                  endBottomClassName={styles.link__arrow__bottom}
                />
              </span>
            </Link>
          </div>
        </div>
      </div>
    </Waypoint>
  )
}

FeaturedProjectThumb.propTypes = {
  project: PropTypes.object,
}

export { FeaturedProjectThumb }
