// extracted by mini-css-extract-plugin
export var BigImage = "BigImage-module--BigImage--8fwtA";
export var aniSpin = "BigImage-module--ani-spin--l2S7p";
export var caption = "BigImage-module--caption--1Z6Bo";
export var counter = "BigImage-module--counter--S3xGq";
export var embla = "BigImage-module--embla--Dvw4C";
export var embla__container = "BigImage-module--embla__container--QBgIW";
export var embla__item = "BigImage-module--embla__item--vYTva";
export var inner = "BigImage-module--inner--AYslV";
export var item = "BigImage-module--item--bZrEG";
export var item__link = "BigImage-module--item__link--6nALc";