import React from 'react'
import PropTypes from 'prop-types'
import { sortBy } from '@/utils/sortBy'
import { Link } from '@/components/Link'
import { Grid, GridRow, GridColumn } from '@/components/Grid'
import { Img } from '@/components/Img'
import { Block } from '@/components/Block'
import { Container } from '@/components/Container'
import * as styles from './ProfilesGrid.module.scss'

const ProfilesGrid = ({ profiles, heading }) => {
  const sortedProfiles = sortBy(profiles, 'name')

  return (
    <Block>
      {heading && (
        <Container>
          <h3 className={styles.heading}>{heading}</h3>
        </Container>
      )}
      <Grid>
        <GridRow>
          {sortedProfiles.map((profile) => {
            const profileImage =
              profile.image?.srcset || profile.image?.src ? (
                <Img {...profile.image} fillContainer />
              ) : null

            const profileTitle = (
              <>
                <span className={styles.item__name}>{profile.name}</span>
                <span className={styles.item__role}>{profile.role}</span>
              </>
            )

            return (
              <GridColumn
                className={styles.column}
                width="2"
                key={profile.name}
              >
                <div>
                  <div className={styles.item__image}>
                    <Link to={profile.route}>{profileImage}</Link>
                  </div>
                  <div>
                    <Link to={profile.route}>{profileTitle}</Link>
                  </div>
                </div>
              </GridColumn>
            )
          })}
        </GridRow>
      </Grid>
    </Block>
  )
}

ProfilesGrid.defaultProps = {
  profiles: [],
}

ProfilesGrid.propTypes = {
  profiles: PropTypes.arrayOf(PropTypes.PropTypes.object),
  heading: PropTypes.string,
}

export { ProfilesGrid }
