import { useStaticQuery, graphql } from 'gatsby'
import { linkResolver } from '@/prismic/helpers/linkResolver'
import { getImage } from '@/prismic/helpers/getImage'

export const useProjects = () => {
  const { allPrismicProject } = useStaticQuery(
    graphql`
      query GetProjects {
        allPrismicProject {
          edges {
            node {
              id
              uid
              type
              data {
                title
                short_summary
                categories {
                  category {
                    uid
                  }
                }
                tags {
                  tag {
                    uid
                  }
                }
                image {
                  fixed(width: 1000) {
                    ...GatsbyPrismicImageFixed_noBase64
                  }
                  url
                  alt
                  dimensions {
                    width
                    height
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  return allPrismicProject.edges.map(({ node }) => {
    const categories = node.data.categories.map(({ category }) => {
      return category?.uid
    })

    const tags = node.data.tags.map(({ tag }) => {
      return tag.uid
    })

    return {
      title: node?.data?.title,
      id: node.id,
      uid: node.uid,
      route: linkResolver(node),
      image: getImage(node?.data?.image),
      shortSummary: node.data.short_summary,
      categories,
      tags,
    }
  })
}
