import { useStaticQuery, graphql } from 'gatsby'
import { linkResolver } from '@/prismic/helpers/linkResolver'
import { getImage } from '@/prismic/helpers/getImage'

export const useProfiles = (max) => {
  const { allPrismicProfile } = useStaticQuery(
    graphql`
      query GetProfiles {
        allPrismicProfile {
          edges {
            node {
              id
              uid
              type
              data {
                name
                role
                current_staff
                image {
                  fixed(width: 300) {
                    ...GatsbyPrismicImageFixed_noBase64
                  }
                }
                profile_page
              }
            }
          }
        }
      }
    `
  )

  let profiles = []
  allPrismicProfile.edges.forEach(({ node }) => {
    const hasPage = node.data?.profile_page === 'True'
    const isCurrent = node.data?.current_staff === 'True'
    if (isCurrent) {
      profiles.push({
        id: node.id,
        name: node.data?.name,
        role: node.data?.role,
        image: getImage(node.data?.image),
        route: hasPage ? linkResolver(node) : '',
        profile_page: hasPage,
      })
    }
  })

  if (max) {
    profiles = profiles.slice(0, max)
  }

  return profiles
}
