import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import * as styles from './ImageCredit.module.scss'

const ImageCredit = ({ className, children }) => {
  return (
    <div className={classNames(styles.ImageCredit, className)}>{children}</div>
  )
}

ImageCredit.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

export { ImageCredit }
