import React, { useState, useCallback, useEffect } from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import Autoplay from 'embla-carousel-autoplay'
import { Container } from '@/components/Container'
import { Img } from '@/components/Img'
import { Block } from '@/components/Block'
import { ImageCredit } from '@/components/ImageCredit'
import * as styles from './BigImage.module.scss'

const BigImage = ({ images }) => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const totalImages = images?.length || 0

  const [emblaRef, emblaApi] = useEmblaCarousel(
    { loop: true, active: totalImages > 1 },
    [
      Autoplay({
        delay: 5000,
        stopOnMouseEnter: true,
        stopOnInteraction: false,
      }),
    ]
  )

  const onSelect = useCallback((emblaApi) => {
    setSelectedIndex(emblaApi.selectedScrollSnap())
  }, [])

  useEffect(() => {
    if (!emblaApi) return

    onSelect(emblaApi)
    emblaApi.on('reInit', onSelect)
    emblaApi.on('select', onSelect)
  }, [emblaApi, onSelect])

  return (
    <Block className={styles.BigImage}>
      <Container>
        <div className={styles.inner}>
          {totalImages > 1 && (
            <div className={styles.counter}>
              {selectedIndex + 1} of {totalImages}
            </div>
          )}
          <div className={styles.embla} ref={emblaRef}>
            <div className={styles.embla__container}>
              {images?.map((item) => {
                return (
                  <div className={styles.embla__item} key={item.id}>
                    {item.image?.src && (
                      <>
                        <button
                          type="button"
                          onClick={item.image.onClick}
                          className={styles.item__link}
                        >
                          <Img {...item.image} showPlaceholder />
                        </button>
                        {item.credit ? (
                          <ImageCredit className={styles.caption}>
                            Image: {item.credit}
                          </ImageCredit>
                        ) : (
                          <ImageCredit>&nbsp;</ImageCredit>
                        )}
                      </>
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </Container>
    </Block>
  )
}

export { BigImage }
