import React from 'react'
import PropTypes from 'prop-types'
import { Block } from '@/components/Block'
import { Container } from '@/components/Container'
import { RichText } from '@/components/RichText'
import { TextAccordionItem } from '@/components/TextAccordionItem'
import * as styles from './TextAccordion.module.scss'

const TextAccordion = ({ heading, body, items }) => {
  return (
    <Block>
      <Container>
        <div className={styles.container}>
          <div className={styles.side}>
            {heading && <h3 className={styles.heading}>{heading}</h3>}
          </div>
          <div className={styles.main}>
            {body && (
              <div className={styles.body}>
                <RichText>{body}</RichText>
              </div>
            )}
            {!!items?.length && (
              <div className={styles.items}>
                {items.map((item) => {
                  return (
                    <TextAccordionItem
                      key={item.uid}
                      heading={item.heading}
                      content={item.content}
                    />
                  )
                })}
              </div>
            )}
          </div>
        </div>
      </Container>
    </Block>
  )
}

TextAccordion.propTypes = {
  heading: PropTypes.string,
  body: PropTypes.node,
  items: PropTypes.arrayOf(PropTypes.PropTypes.object),
}

export { TextAccordion }
